export default function block_common() {
  $('.felds-block .scroll-down a').click(function(e){
    e.preventDefault();

    var pos = $($(this).attr('href')).offset().top - $('#header').outerHeight();
    $('html, body').animate({
      scrollTop: pos
    }, 800);
  });

  // Social popup open
	$('.felds-block .share a').click(function(e){
		e.preventDefault();
		const link = $(this).attr("href");
		const w = 670;
		const h = 378;
		const y = window.outerHeight / 2 + window.screenY - (h / 2)
		const x = window.outerWidth / 2 + window.screenX - (w / 2)
		window.open(link, "_blank", 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);
	});
}
