export default function nav() {
  $('.hamburger').click(function(e){
    e.preventDefault();

    $('.mobile-menu').toggleClass('opened');
  });

  $('.menu-item-has-children > a').click(function(e){
    e.preventDefault();

    $(this).toggleClass('opened');
    $(this).parent().find('.sub-menu').slideToggle();
  });
}
