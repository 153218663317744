export default function block_faq() {
  $('.felds-faq-block .block-content .item-title').click(function(e){
    e.preventDefault();
    
    if (!$(this).parent().hasClass('opened')) {
      $(this).parent().parent().find('.item').removeClass('opened');
      $(this).parent().parent().find('.item-content').slideUp();
    }

    $(this).parent().toggleClass('opened');
    $(this).parent().find('.item-content').slideToggle();
  });
}
