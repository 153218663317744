import { isMobile } from "./common";

export default function block_featured_shows() {
  if (isMobile()) {
    $('.felds-featured-shows-block .shows').addClass('owl-carousel');
    $('.felds-featured-shows-block .shows').owlCarousel({
      items: 1,
      margin: 0,
      autoplay: false,
      slideTransition: 'linear',
      smartSpeed: 500,
      mouseDrag: true,
      touchDrag: true,
      loop: true,
      nav: true,
      onChange: cbChange,
      navText: ['<img src="' + wp.theme_url + '/dist/img/icon/prev-icon.png">', '<img src="' + wp.theme_url + '/dist/img/icon/next-icon.png">'],
    });
    function cbChange(event) {
      setTimeout(() => {
        // Directly select the source element within the active item-video of the carousel
        const videoSource = $('.owl-item.active .item-video source');

        // Get the 'data-url' attribute from the selected source element
        const videoUrl = videoSource.attr('data-url');

        // Get the current src
        const currentSrc = videoSource.attr('src');

        // Check if a URL was found and the src is empty to avoid errors and unnecessary reloads
        if (videoUrl && !currentSrc) {
          // Set the 'src' attribute of the parent video element to the new URL
          videoSource.attr('src', videoUrl);

          // Reload the video to apply the new source
          videoSource.parent().get(0).load();
          // videojs(document.querySelectorAll('.video-js'));

          // Optionally, play the video if it's supposed to auto-play
          // videoSource.parent().get(0).play();
        }
      }, 500); // Adjust this delay as necessary
    }

    // Select all video elements with the specified selector
    var videoElements = document.querySelectorAll('.video-js');
    // Loop through each video element and initialize Video.js
    videoElements.forEach(function(videoElement) {
      videojs(videoElement);
    });
  }
}
