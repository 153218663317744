export default function block_faq_anchors() {
  $('.felds-faq-anchors-block a').click(function(e){
    e.preventDefault();

    var pos = $($(this).attr('href')).offset().top;
    $('html, body').animate({
      scrollTop: pos
    }, 800);
  });
}
