export default function common() {
  var disneyClient = $("#disneyid-script");
  var didConfig = {
      clientId: disneyClient.data("client-id"),
      responderPage: disneyClient.data("responder-url"),
      debug: false
  };
  var did;

  $('a.did-opt-in, .did-opt-in > a').click(function(e) {
    e.preventDefault();
	
	if(typeof OneID !== 'undefined') {
		if (!did) did = OneID.get(didConfig);
		did.launchRegistration();		
	}
  });

  $('#header .hamburger').on('click', function (e) {
		e.preventDefault();
		$( this ).toggleClass( 'is-active' );
		$( '.menu-links' ).toggleClass( 'active' );
    $( 'body' ).toggleClass( 'mobile-menu-opened' );
  });

  // Language Switcher
  $('.language-switcher > a').click(function(e){
    // e.preventDefault();
  });


  $('.gallery-content a').magnificPopup({
    type: 'image',
    verticalFit: true,
    closeMarkup: '<img class="mfp-close" src="' + wp.theme_url + '/dist/img/icon/close.svg">',
    overflowY: 'hidden',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1],
      arrowMarkup: '<i class="far fa-angle-%dir% btn-%dir%"></i>',
      tCounter: '%curr% of %total%'
    }
  });
}

const isMobile = () => {
  return $(window).width() < 992;
}

export { isMobile };